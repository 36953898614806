<template>
  <div class="space-y-1">
    <div v-if="label" class="flex justify-between">
      <label
        :for="id"
        class="block text-sm font-medium text-gray-900 leading-6"
        :class="{ 'sr-only': srOnly }"
      >
        {{ label }}
      </label>
      <span v-if="!required" :id="`${id}-optional`" class="text-sm text-gray-500 leading-6">
        Optional
      </span>
    </div>
    <div
      class="relative rounded-md shadow-sm"
      :class="{
        'text-white focus-within:text-gray-600': dark,
        'text-gray-800': !dark,
        'mt-2': label?.length,
      }"
    >
      <div
        v-if="$slots.icon"
        class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center"
        aria-hidden="true"
      >
        <slot name="icon" size="h-5 w-5" />
      </div>
      <input
        v-bind="$attrs"
        :id="id"
        ref="refBaseText"
        v-model="internalInputVal"
        :type="type"
        :class="inputClass"
        :aria-invalid="hasErrors ? 'true' : undefined"
        :aria-describedby="$slots.default ? `${id}-desc` : undefined"
      />
      <div
        v-if="hasErrors"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <ExclamationCircleIcon class="h-5 w-5" :class="{ 'text-red-600': hasErrors }" />
      </div>
      <div
        v-if="resetable && internalInputVal"
        class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
      >
        <XMarkIcon class="h-5 w-5" @click="internalInputVal = ''" />
      </div>
    </div>

    <template v-if="hasErrors">
      <p
        v-for="error of errors"
        :id="`${id}-error`"
        :key="error.$uid"
        class="mt-2 text-sm text-red-600"
      >
        {{ error.$message }}
      </p>
    </template>
    <p v-else-if="$slots.default" :id="`${id}-desc`" class="mt-6 text-sm text-gray-500">
      <slot />
    </p>
  </div>
</template>

<script setup lang="ts">
  import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/vue/20/solid'
  import { type ErrorObject } from '@vuelidate/core'
  import { computed, onMounted, ref, useSlots, useTemplateRef } from 'vue'

  defineOptions({
    inheritAttrs: false,
  })

  interface IProps {
    id: string
    modelValue?: string | number
    type?: string
    label?: string
    srOnly?: boolean
    required?: boolean
    resetable?: boolean
    dark?: boolean
    errors?: ErrorObject[]
  }
  const {
    modelValue = undefined,
    type = 'text',
    label = undefined,
    srOnly = false,
    required = false,
    resetable = false,
    dark = false,
    errors = undefined,
  } = defineProps<IProps>()

  const emits = defineEmits(['update:modelValue', 'mounted'])
  const slots = useSlots()
  const refBaseText = useTemplateRef('refBaseText')

  const internalInputVal = computed({
    get() {
      return modelValue
    },
    set(modelValue) {
      emits('update:modelValue', modelValue)
    },
  })

  const hasErrors = computed(() => errors && errors.length > 0)

  const inputClass = computed(() => ({
    'block w-full focus:outline-none sm:text-sm rounded-md border-0 py-1.5 sm:leading-6 ring-1 ring-inset focus:ring-inset focus:ring-2 ':
      true,
    'pl-10': slots.icon,
    'pr-10 text-red-900 placeholder:text-red-300 ring-red-300 focus:ring-red-500 ': hasErrors.value,
    'bg-white bg-opacity-20 focus:text-gray-900 placeholder:text-white focus:outline-none focus:bg-opacity-100 focus:placeholder-gray-500 focus:ring-0':
      dark,
    'placeholder:text-gray-400 ring-gray-300 focus:ring-indigo-500': !dark,
  }))

  onMounted(() => emits('mounted'))
</script>
