<script lang="ts" setup>
  import { useQuery } from '@vue/apollo-composable'
  import { computed } from 'vue'

  import { SearchGroupsDocument } from '@/graphqlTypes'
  import { useUser } from '@/stores/user'

  const store = useUser()

  const { loading, result } = useQuery(SearchGroupsDocument, {
    text: 'skedr',
    perPage: 1,
    page: 1,
    userId: store.state.tenantId,
  })

  const showBanner = computed(() => {
    const groupIds = result.value?.searchGroups.groups.map(({ id }) => id) ?? []
    return !loading.value && groupIds.filter((id) => id === '14835830@N25').length !== 1
  })
</script>

<template>
  <BaseBanner v-if="showBanner" ref="banner" class="text-sm leading-6 items-center">
    <strong class="font-semibold">
      We're looking for amazing creative from the Skedr community to feature in our blogs and
      Instagram
    </strong>
    <svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
      <circle cx="1" cy="1" r="1" />
    </svg>
    Get involved in our
    <a href="https://www.flickr.com/groups/14835830@N25/" class="underline" target="_blank">
      Skedr group
    </a>
    <span aria-hidden="true">&rarr;</span>
  </BaseBanner>
</template>
