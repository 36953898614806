import type { RouteRecordRaw } from 'vue-router'

import { AppLayout, AuthLayout } from '@/composables/useLayout'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/acc',
  },
  {
    path: '/auth/sign-in',
    name: 'Signin',
    component: () => import('../views/auth/SignInUser.vue'),
    meta: { layout: AuthLayout, title: 'Sign in' },
  },
  {
    path: '/auth/create-account',
    name: 'CreateAccount',
    components: {
      default: () => import('../views/auth/CreateAccount.vue'),
      side: () => import('../views/auth/CreateAccountAdditionalInfo.vue'),
    },
    meta: { layout: AuthLayout, title: 'Create new account' },
  },
  {
    path: '/auth/validate-account',
    name: 'ValidateAccount',
    component: () => import('../views/auth/ValidateAccount.vue'),
    meta: { layout: AuthLayout, title: 'Validate account' },
  },
  {
    path: '/auth/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/auth/ForgotPassword.vue'),
    meta: { layout: AuthLayout, title: 'Forgot password' },
  },
  {
    path: '/auth/sign-up',
    redirect: '/auth/create-account',
  },
  {
    path: '/auth/login',
    redirect: '/auth/sign-in',
  },
  {
    path: '/auth/reset-password',
    redirect: '/auth/forgot-password',
  },
  {
    path: '/groups',
    redirect: '/acc/groups',
  },
  {
    path: '/autoimport-tags/*',
    redirect: '/acc/groups',
  },
  {
    path: '/auth/*',
    redirect: '/auth/login',
  },
  {
    path: '/acc',
    name: 'Account',
    components: {
      default: () => import('../components/layouts/TheAppWrapper.vue'),
    },
    redirect: '/acc/groups',
    meta: { layout: AppLayout, protected: true },
    children: [
      {
        path: 'groups',
        name: 'MyGroups',
        meta: { title: 'My groups' },
        components: {
          default: () => import('../views/MyGroups.vue'),
          tagCloud: () => import('../components/sidebar/TheTagCloud.vue'),
          subscriptionBanner: () => import('../components/sidebar/TheSubscriptionBanner.vue'),
          howTo: () => import('../components/sidebar/TheHowTo.vue'),
        },
      },
      {
        path: 'groups/:tag',
        name: 'GroupsByTag',
        meta: { title: 'Groups by tag' },
        components: {
          default: () => import('../views/GroupsByTag.vue'),
          tagCloud: () => import('../components/sidebar/TheTagCloud.vue'),
          subscriptionBanner: () => import('../components/sidebar/TheSubscriptionBanner.vue'),
          howTo: () => import('../components/sidebar/TheHowTo.vue'),
        },
      },
      {
        path: 'groups/s/:searchTerm',
        name: 'SearchGroups',
        meta: { title: 'Searching groups' },
        components: {
          default: () => import('../views/SearchGroups.vue'),
        },
      },
      {
        path: 'photostream/:tag?',
        name: 'MyPhotostream',
        meta: { title: 'My Photostream' },
        components: {
          default: () => import('../views/MyPhotostream.vue'),
          queueGraph: () => import('../components/sidebar/TheQueueGraph.vue'),
          tagCloud: () => import('../components/sidebar/TheTagCloud2.vue'),
          subscriptionBanner: () => import('../components/sidebar/TheSubscriptionBanner.vue'),
          howTo: () => import('../components/sidebar/TheShareColor.vue'),
        },
      },
      {
        path: 'scheduled',
        name: 'Scheduled',
        meta: { title: 'Scheduled photos' },
        components: {
          default: () => import('../views/ScheduledPhotos.vue'),
        },
      },
      {
        path: 'scheduled/:by/:value',
        name: 'ScheduledBy',
        meta: { title: 'Scheduled photos' },
        components: {
          default: () => import('../views/ScheduledPhotos.vue'),
        },
      },
    ],
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      default: () => import('../views/settings/Settings.vue'),
    },
    redirect: '/settings/profile',
    meta: { layout: AppLayout, protected: true },
    children: [
      {
        path: 'profile',
        name: 'Profile',
        meta: { title: 'Settings - profile' },
        component: () => import('../views/settings/ProfileSection.vue'),
      },
      {
        path: 'email',
        name: 'Email',
        meta: { title: 'Settings - email' },
        component: () => import('../views/settings/EmailSection.vue'),
      },
      {
        path: 'permissions',
        name: 'Permissions',
        meta: { title: 'Settings - permissions' },
        component: () => import('../views/settings/PermissionsSection.vue'),
      },
      {
        path: 'password',
        name: 'Password',
        meta: { title: 'Settings - password' },
        component: () => import('../views/settings/PasswordSection.vue'),
      },
      {
        path: 'subscription',
        name: 'Subscription',
        meta: { title: 'Settings - subscription' },
        component: () => import('../views/settings/SubscriptionSection.vue'),
      },
      {
        path: 'credits',
        name: 'Credits',
        meta: { title: 'Settings - credits' },
        component: () => import('../views/settings/CreditsSection.vue'),
      },
    ],
  },
  // {
  //   path: '/*',
  //   name: '404NotFound',
  //   component: () => import('../views/404NotFound.vue'),
  //   meta: { layout: AppLayout, protected: true }
  // }
]
export default routes
